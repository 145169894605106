import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingNode,
  FloatingOverlay,
  FloatingPortal,
  offset,
  shift,
  size,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useInteractions,
  useTransitionStyles,
} from "@floating-ui/react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { MouseEventHandler, useCallback, useLayoutEffect } from "react";

import { isPlacementHorizontal } from "@kraaft/helper-functions";

import { Spacing } from "../../../constants";
import {
  executeAfterStatePropagation,
  getSpacingFromSizeValue,
} from "../../../utils";
import { AnchoredSheetDefinition } from "./anchoredSheet.definition/anchoredSheet.definition.types";

const ANCHORED_SHEET_TRANSITION_DURATION = 150;

function getScale(value: number) {
  return `scale(${value}, ${value ** 2})`;
}

export const AnchoredSheetHost: AnchoredSheetDefinition["Host"] = ({
  children,
  open,
  onClose,
  anchor,
  placement = "bottom-start",
  offsetSpacing = "S8",
  followRefWidth,
  bubbleOnClose,
  withoutBackdrop,
}) => {
  const classes = useStyles();
  const nodeId = useFloatingNodeId();

  const handleOpenChange = useCallback(
    (newOpen: boolean) => {
      if (newOpen === false) {
        onClose();
      }
    },
    [onClose],
  );

  const offsetSpacingNumber = getSpacingFromSizeValue(offsetSpacing);

  const { refs, floatingStyles, context } = useFloating({
    open,
    elements: {
      reference:
        anchor?.current && anchor.current instanceof Element
          ? anchor?.current
          : undefined,
    },
    onOpenChange: handleOpenChange,
    placement,
    middleware: [
      size({
        apply({ availableHeight, elements, rects, placement: _placement }) {
          // Cannot alter style directly, need to wait a render, otherwise
          // MutationObserver crashes
          executeAfterStatePropagation(() => {
            const offsetSpacingCompensation = isPlacementHorizontal(_placement)
              ? 0
              : offsetSpacingNumber;

            elements.floating.style.maxHeight = `${
              availableHeight - Spacing.S8 - offsetSpacingCompensation
            }px`;

            if (followRefWidth) {
              elements.floating.style.width = `${rects.reference.width}px`;
            }
          });
        },
      }),
      offset(getSpacingFromSizeValue(offsetSpacingNumber)),
      flip({ fallbackAxisSideDirection: "end" }),
      shift(),
    ],
    whileElementsMounted: autoUpdate,
    nodeId,
  });

  // https://github.com/floating-ui/floating-ui/issues/2458#issuecomment-1646510707
  useLayoutEffect(() => {
    if (!anchor?.current) {
      return;
    }
    if (anchor.current instanceof Element) {
      return;
    }
    if (!open) {
      return;
    }

    refs.setPositionReference(anchor?.current);
  }, [refs, anchor, open]);

  const dismiss = useDismiss(context, {
    ancestorScroll: withoutBackdrop,
    bubbles: bubbleOnClose ?? false, // allows the sheet to be dismissed without dismissing the parent. Ex: database filter menu
  });

  const { getFloatingProps } = useInteractions([dismiss]);

  const { isMounted, styles } = useTransitionStyles(context, {
    duration: ANCHORED_SHEET_TRANSITION_DURATION,
    initial: {
      transform: getScale(0.75),
      opacity: 0,
    },
    common: ({ placement: _placement }) => ({
      transformOrigin: {
        top: "bottom",
        "top-end": "bottom right",
        "top-start": "bottom left",
        bottom: "top",
        "bottom-end": "top right",
        "bottom-start": "top left",
        left: "right",
        "left-end": "right bottom",
        "left-start": "right top",
        right: "left",
        "right-end": "left bottom",
        "right-start": "left top",
      }[_placement],
      transform: getScale(1),
      opacity: 1,
    }),
  });

  const stopPropagation = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      event.stopPropagation();
    },
    [],
  );

  return (
    <FloatingNode id={nodeId}>
      {isMounted ? (
        <FloatingPortal>
          <FloatingOverlay
            className={classes.overlay}
            onClick={stopPropagation}
            style={{ pointerEvents: withoutBackdrop ? "none" : undefined }}
          >
            <FloatingFocusManager
              context={context}
              modal={false}
              visuallyHiddenDismiss
              closeOnFocusOut={false}
            >
              <div
                className={clsx(
                  "active-popover",
                  classes.content,
                  !open && classes.noPointer,
                )}
                ref={refs.setFloating}
                style={floatingStyles}
                {...getFloatingProps()}
              >
                <div style={styles} className={classes.sheetContent}>
                  {children}
                </div>
              </div>
            </FloatingFocusManager>
          </FloatingOverlay>
        </FloatingPortal>
      ) : null}
    </FloatingNode>
  );
};

const useStyles = makeStyles({
  overlay: {
    zIndex: 1550, // above all (including popup sheets and legacy dialogs)
    display: "flex",
  },
  content: {
    display: "flex",
  },
  noPointer: {
    "& *": {
      pointerEvents: "none",
    },
  },
  sheetContent: {
    pointerEvents: "all",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexShrink: 1,
    width: "100%",
  },
});
