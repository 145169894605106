import { DependencyList, EffectCallback, useEffect, useRef } from "react";

/**
 * useUpdate is a custom React hook that executes a callback function whenever the specified dependencies change, but skips invoking the callback on the initial mount.
 *
 * @param fn The callback function to execute when dependencies change.
 * @param propsToObserve An array of dependencies that the effect listens to for changes.
 *
 * @example
 * const MyComponent = () => {
 *   const [count, setCount] = useState(0);
 *
 *   useUpdate(() => {
 *     console.log('Count changed:', count);
 *   }, [count]);
 *
 *   return (
 *     <div>
 *       <p>{count}</p>
 *       <button onClick={() => setCount(prev => prev + 1)}>Increment</button>
 *     </div>
 *   );
 * }
 */
export function useUpdate(fn: EffectCallback, propsToObserve: DependencyList) {
  const isInitialMount = useRef(true);
  useEffect(() => {
    // We just don't execute fn() the first time.
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    return fn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, propsToObserve);
}
