import { RefObject, useCallback, useRef } from "react";

import { uuid } from "@kraaft/helper-functions";
import { useUpdate } from "@kraaft/helper-hooks";

import { ActionSheetItemPropsWithKey } from "../baseContextMenu/contextMenu.types";
import {
  ImperativeActionContextMenu,
  ImperativeContextMenuHandle,
} from "../baseContextMenu/imperativeActionContextMenu";
import { useContextMenuListener } from "../baseContextMenu/useContextMenuListener";

export type UseLocalActionContextMenuProps = {
  data: Array<ActionSheetItemPropsWithKey>;
  contextAnchor: RefObject<HTMLElement | unknown> | null;
};

/**
 * Hook used to create a local context menu.
 * You won't need any Context to use it, but you'll need to add in your JSX yourself.
 *
 * @param options - The options for configuring the context menu.
 * @param options.contextAnchor - The anchor element that the context menu will be positioned relative to.
 * @param options.data - The data that will be passed to the context menu.
 *
 * Usage
 * ```typescript jsx
 * const YourComponent = () => {
 *  const divRef = useRef<HTMLElement>(null);
 *
 *  const { ActionContextMenu, open } = useLocalActionContextMenu({
 *     contextAnchor: null,
 *     data: [{ label: "hello there", onClick: () => console.log("hello there") }],
 *   });
 *
 *  return (
 *    <div>
 *      <div ref={divRef}/>
 *      <ActionContextMenu />
 *    </div>);
 * }
 * ```
 *
 * @return - An object containing the following properties:
 *   - ActionContextMenu: A component that renders the context menu.
 *   - open: A function that opens the context menu.
 */
export function useLocalActionContextMenu({
  contextAnchor,
  data,
}: UseLocalActionContextMenuProps) {
  const imperativeContextMenuRef = useRef<ImperativeContextMenuHandle>(null);
  const id = useRef<string>(uuid());

  useContextMenuListener(contextAnchor, (virtualElement) => {
    imperativeContextMenuRef.current?.openWith(
      id.current,
      data,
      virtualElement,
    );
  });

  const open = useCallback(
    () => imperativeContextMenuRef.current?.openWith(id.current, data),
    [imperativeContextMenuRef, data],
  );

  useUpdate(() => {
    imperativeContextMenuRef.current?.updateState(id.current, data);
  }, [data]);

  const renderComponent = useCallback(() => {
    return <ImperativeActionContextMenu ref={imperativeContextMenuRef} />;
  }, []);

  return {
    ActionContextMenu: renderComponent,
    open,
  };
}
